import React from 'react';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { Box, List, Divider, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import LockResetIcon from '@mui/icons-material/LockReset';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../../../store/useAuthStore';
import { LanguageSelector } from '../LanguageSelector';
import { AvatarFL } from '../AvatarFL';

export const ProfileMenu = ({ openDrawer, toggleDrawer, creator }) => {
    const ANCHOR = 'right';
    const navigate = useNavigate();
    const logout = useAuthStore((state) => state.logout);

    const signOut = () => logout();

    const itensMenu = [
        { text: 'Alterar Senha', icon: <LockResetIcon />, onClick: () => navigate('/createNewPassword') },
        { text: 'Alterar Meus Dados', icon: <EditNoteIcon />, onClick: () => handlerEditUserInfo() },
        { text: 'Sair do Painel', icon: <ExitToAppIcon />, onClick: () => signOut() },
    ];

    const handlerEditUserInfo = () => {
        navigate('/editUserInfo');
        toggleDrawer();
    };

    const list = () => (
        <Box sx={{ width: '38vh', marginTop: 10 }} role="presentation">
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: 2,
                }}
            >
                <AvatarFL name={creator?.nome} onClick={toggleDrawer} />
                <Box sx={{ marginTop: 2, marginBottom: 2, textAlign: 'center', color: 'var(--grey-700-color)' }}>
                    <Typography variant="h5">{creator?.nome}</Typography>
                    <Typography variant="body1" sx={{ paddingTop: 0.8 }}>
                        {creator?.email}
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ marginTop: 2, marginBottom: 2, textAlign: 'center', color: 'var(--grey-600-color)' }}>
                <LanguageSelector />
            </Box>
            <Divider />
            <List>
                {itensMenu.map((item) => (
                    <ListItem key={item.text} onClick={item.onClick} sx={{ color: 'var(--primary-color)' }}>
                        <ListItemButton>
                            <ListItemIcon sx={{ color: 'var(--primary-color)' }}>{item.icon}</ListItemIcon>
                            <ListItemText primary={item.text} />
                        </ListItemButton>
                        <Divider />
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    return (
        <>
            <SwipeableDrawer anchor={ANCHOR} open={openDrawer} onClose={toggleDrawer} onOpen={toggleDrawer}>
                {list(ANCHOR)}
            </SwipeableDrawer>
        </>
    );
};
