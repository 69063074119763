import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { Post } from "../../components/post";
import { RankingCard } from "./rankingCard";
import { Opportunities } from "./opportunities";
import { useParams } from "react-router-dom";
import { apiGetCommunity } from "../../services/apiGetCommunity";
import { useErrorStore } from "../../store/useErrorStore";
import { Loader } from "../../components/ui/Loader";
import { returnEnvironment } from "../../utils";
import { apiGetOportunidades } from "../../services/apiGetOportunidades";

export const Community = () => {
    const [isLoading, setIsLoading] = useState(null);
    const [community, setCommunity] = useState(null);
    const [opportunities, setOpportunities] = useState([]);
    const { id } = useParams();

    const showError = useErrorStore((state) => state.showError);

    const isWizard = returnEnvironment() === "wizard";

    const fetchData = async () => {
        try {
            setIsLoading(true);

            if (id) {
                const dataCommunity = await apiGetCommunity(id);
                setCommunity(dataCommunity);
            }

            const dataOpportunities = await apiGetOportunidades();
            setOpportunities(dataOpportunities);
        } catch (error) {
            console.log("error", error);
            showError(error);
            setCommunity(null);
            setOpportunities(null);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const effect = async () => {
            await fetchData();
        };
        effect();
    }, [id]);

    if (isLoading) return <Loader />;
    if (!community?.id) return <Typography>Community not found</Typography>;

    return (
        <Box sx={{ padding: 3 }}>
            {!isWizard && (
                <Typography variant="h4" sx={{ my: 2 }}>
                    {community?.titulo}
                </Typography>
            )}
            <Opportunities opportunities={opportunities} />

            <RankingCard community={community} />
            <Post community={community} />
        </Box>
    );
};
